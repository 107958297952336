<template>
    <div class="flex flex-col h-full bg-gray-100">
        <div class="py-10">
            <div class="md:rounded-3xl w-full lg:w-7/12 py-10 mx-auto mb-12 bg-white shadow">
                <div v-if="step === maxSteps">
                    <Complete :token="token" :trx="trx" :failed="failed"/>
                </div>
                <div v-if="step !== maxSteps" class="">
                    <!-- Top Navigation -->
                    <div class="px-8 py-4 border-b-2">
                        <div class="mb-1 text-xs font-bold leading-tight tracking-wide text-gray-500 uppercase">
                            Step: {{ step }} of {{ maxSteps }}
                        </div>
                        <div class="md:flex-row md:items-center md:justify-between flex flex-col">
                            <div class="flex-1">
                                <div v-if="step === 1">
                                    <div class="text-lg font-bold leading-tight text-left text-gray-700">
                                        Select token type
                                    </div>
                                </div>

                                <div v-if="step === 2">
                                    <div class="text-lg font-bold leading-tight text-left text-gray-700">
                                        Token details
                                    </div>
                                </div>

                                <div v-if="step === 3">
                                    <div class="text-lg font-bold leading-tight text-left text-gray-700">
                                        Review and deploy token
                                    </div>
                                </div>
                            </div>

                            <div class="md:w-64 flex items-center">
                                <div class="w-full mr-2 bg-gray-100 rounded-full">
                                    <div
                                        class="h-2 text-xs leading-none text-center text-white bg-green-500 rounded-full"
                                        :style="'width: ' + parseInt((step / maxSteps) * 100) + '%'"
                                    ></div>
                                </div>
                                <div class="w-10 text-xs text-gray-600"></div>
                            </div>
                        </div>
                    </div>
                    <!-- /Top Navigation -->

                    <!-- Step Content -->
                    <div>
                        <div v-if="step === 1" class="md:px-8 px-2 py-10">
                            <div class="flex flex-wrap">
                                <Card
                                    :token="token"
                                    v-if="token.listed"
                                    :loading="loadingPrices"
                                    v-for="token in tokenDetails"
                                    :key="token.type"
                                />
                            </div>
                            <div class="flex w-full">
                                <div class="max-w-9xl sm:px-6 lg:px-8 lg:mt-5 relative px-4 mx-auto mt-4">
                                    <div class="lg:max-w-7xl max-w-md mx-auto">
                                        <div class="sm:p-10 lg:flex lg:items-center px-6 py-8 bg-gray-100 rounded-lg">
                                            <div class="flex-1">
                                                <div>
                                                    <h3
                                                        class="inline-flex px-4 py-1 text-sm font-semibold tracking-wide text-gray-800 uppercase bg-white rounded-full"
                                                    >
                                                        Free Token
                                                    </h3>
                                                </div>
                                                <div class="mt-4 text-lg text-gray-600">
                                                    Tokengen also offers a free token for everyone. This token includes
                                                    custom name and symbol aswell as a verified contract on BSCScan. All
                                                    free tokens will have a copyright text on their contract, showing
                                                    where the contract is deployed from.
                                                    <b>You pay only for gas fees</b>
                                                </div>
                                            </div>
                                            <div class="lg:mt-0 lg:ml-10 lg:flex-shrink-0 mt-6 rounded-md shadow">
                                                <button
                                                    @click="setToken('FreeToken')"
                                                    class="hover:bg-gray-50 flex items-center justify-center px-5 py-3 text-base font-medium text-gray-900 bg-white border border-transparent rounded-md"
                                                >
                                                    Get Started
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="step === 2" class="md:px-8 px-2 py-10">
                            <div class="md:flex-row flex flex-col flex-wrap w-full">
                                <div class="md:w-1/2 flex flex-col w-full pr-8 border-r">
                                    <div v-for="feature in features" v-if="feature.editable">
                                        <text-input
                                            v-model="token[feature.bind]"
                                            v-if="feature.inputType === 'string'"
                                            :text="feature.inputText || feature.text"
                                            :help="feature.help"
                                            :error="errors.token[feature.bind]"
                                            :highlighted="highlighted.includes(feature.bind)"
                                            :readonly="feature.locked"
                                            :disabled="feature.locked"
                                        ></text-input>
                                        <number-input
                                            v-model="token[feature.bind]"
                                            v-if="feature.inputType === 'number'"
                                            :text="feature.inputText || feature.text"
                                            :help="feature.help"
                                            :error="errors.token[feature.bind]"
                                            :highlighted="highlighted.includes(feature.bind)"
                                            :extra="feature.extra || $options.filters.short(token[feature.bind])"
                                            :readonly="feature.locked"
                                            :disabled="feature.locked"
                                            :min="feature.min || 0"
                                            :max="feature.max || 100"
                                            :step="feature.step || 1"
                                        />
                                    </div>
                                </div>
                                <div class="md:w-1/2 flex flex-row w-full pl-8">
                                    <div class="flex flex-col w-1/2">
                                        <ul>
                                            <feature-lines :token="token"/>
                                        </ul>
                                        <div>
                                            <button
                                                @click="decrementStep"
                                                class="focus:outline-none hover:bg-blue-600 whitespace-nowrap w-auto px-5 py-2 font-medium text-center text-white bg-indigo-500 border border-transparent rounded-lg shadow-sm"
                                            >
                                                Select another template
                                            </button>
                                        </div>
                                    </div>
                                    <div class="flex flex-col justify-center w-1/2">
                                        <svg
                                            id="a79c70b2-3a32-4f99-833c-b78d151fe0d8"
                                            data-name="Layer 1"
                                            xmlns="http://www.w3.org/2000/svg"
                                            class="w-auto"
                                            viewBox="0 0 790.52292 730.49185"
                                        >
                                            <title>contract</title>
                                            <path
                                                d="M252.65186,143.094a47.96791,47.96791,0,0,0-47.91332,47.91332V742.99707a47.96778,47.96778,0,0,0,47.91332,47.91272H657.57652a47.96766,47.96766,0,0,0,47.91272-47.91272V191.00728A47.96778,47.96778,0,0,0,657.57652,143.094Z"
                                                transform="translate(-204.73854 -84.75407)"
                                                fill="#3f3d56"
                                            />
                                            <rect
                                                x="24.91599"
                                                y="92.37149"
                                                width="450.91871"
                                                height="579.75263"
                                                fill="#fff"
                                            />
                                            <rect
                                                x="83.86359"
                                                y="196.28941"
                                                width="333.02352"
                                                height="21.87746"
                                                fill="#4f46e5"
                                            />
                                            <rect
                                                x="83.86359"
                                                y="254.6293"
                                                width="333.02352"
                                                height="21.87746"
                                                fill="#4f46e5"
                                            />
                                            <rect
                                                x="83.86359"
                                                y="312.96919"
                                                width="333.02352"
                                                height="21.87746"
                                                fill="#4f46e5"
                                            />
                                            <rect
                                                x="83.86359"
                                                y="371.30907"
                                                width="333.02352"
                                                height="21.87746"
                                                fill="#4f46e5"
                                            />
                                            <rect
                                                x="83.86359"
                                                y="429.64896"
                                                width="333.02352"
                                                height="21.87746"
                                                fill="#4f46e5"
                                            />
                                            <rect
                                                x="83.86359"
                                                y="487.98885"
                                                width="333.02352"
                                                height="21.87746"
                                                fill="#4f46e5"
                                            />
                                            <rect
                                                x="83.86359"
                                                y="546.32874"
                                                width="333.02352"
                                                height="21.87746"
                                                fill="#4f46e5"
                                            />
                                            <rect
                                                x="332.19662"
                                                y="167.05547"
                                                width="335.70794"
                                                height="431.62449"
                                                transform="translate(-265.49105 11.51607) rotate(-10.37053)"
                                                fill="#e6e6e6"
                                            />
                                            <rect
                                                x="352.62694"
                                                y="246.5507"
                                                width="247.93524"
                                                height="16.28772"
                                                transform="translate(-242.80136 5.19986) rotate(-10.37053)"
                                                fill="#4f46e5"
                                            />
                                            <rect
                                                x="360.44561"
                                                y="289.27509"
                                                width="247.93524"
                                                height="16.28772"
                                                transform="translate(-250.36459 7.30526) rotate(-10.37053)"
                                                fill="#4f46e5"
                                            />
                                            <rect
                                                x="368.26429"
                                                y="331.99947"
                                                width="247.93524"
                                                height="16.28772"
                                                transform="translate(-257.92782 9.41067) rotate(-10.37053)"
                                                fill="#4f46e5"
                                            />
                                            <rect
                                                x="376.08296"
                                                y="374.72385"
                                                width="247.93524"
                                                height="16.28772"
                                                transform="translate(-265.49105 11.51607) rotate(-10.37053)"
                                                fill="#4f46e5"
                                            />
                                            <rect
                                                x="383.90164"
                                                y="417.44824"
                                                width="247.93524"
                                                height="16.28772"
                                                transform="matrix(0.98366, -0.18001, 0.18001, 0.98366, -273.05427, 13.62147)"
                                                fill="#4f46e5"
                                            />
                                            <rect
                                                x="391.72032"
                                                y="460.17262"
                                                width="247.93524"
                                                height="16.28772"
                                                transform="translate(-280.6175 15.72687) rotate(-10.37053)"
                                                fill="#4f46e5"
                                            />
                                            <rect
                                                x="399.53899"
                                                y="502.89701"
                                                width="247.93524"
                                                height="16.28772"
                                                transform="translate(-288.18073 17.83227) rotate(-10.37053)"
                                                fill="#4f46e5"
                                            />
                                            <rect
                                                x="776.82937"
                                                y="612.36811"
                                                width="94.98996"
                                                height="122.12995"
                                                transform="translate(-312.49937 74.63623) rotate(-10.37053)"
                                                fill="#e6e6e6"
                                            />
                                            <rect
                                                x="782.61021"
                                                y="634.86162"
                                                width="70.15431"
                                                height="4.60868"
                                                transform="translate(-306.07923 72.84904) rotate(-10.37053)"
                                                fill="#fff"
                                            />
                                            <rect
                                                x="784.82254"
                                                y="646.95066"
                                                width="70.15431"
                                                height="4.60868"
                                                transform="translate(-308.21928 73.44477) rotate(-10.37053)"
                                                fill="#fff"
                                            />
                                            <rect
                                                x="787.03487"
                                                y="659.0397"
                                                width="70.15431"
                                                height="4.60868"
                                                transform="translate(-310.35932 74.0405) rotate(-10.37053)"
                                                fill="#fff"
                                            />
                                            <rect
                                                x="789.2472"
                                                y="671.12874"
                                                width="70.15431"
                                                height="4.60868"
                                                transform="translate(-312.49937 74.63623) rotate(-10.37053)"
                                                fill="#fff"
                                            />
                                            <rect
                                                x="791.45952"
                                                y="683.21779"
                                                width="70.15431"
                                                height="4.60868"
                                                transform="translate(-314.63942 75.23197) rotate(-10.37053)"
                                                fill="#fff"
                                            />
                                            <rect
                                                x="793.67185"
                                                y="695.30683"
                                                width="70.15431"
                                                height="4.60868"
                                                transform="translate(-316.77946 75.8277) rotate(-10.37053)"
                                                fill="#fff"
                                            />
                                            <rect
                                                x="795.88418"
                                                y="707.39587"
                                                width="70.15431"
                                                height="4.60868"
                                                transform="translate(-318.91951 76.42343) rotate(-10.37053)"
                                                fill="#fff"
                                            />
                                            <path
                                                d="M538.60427,115.13943H498.86881v-8.13468a22.25068,22.25068,0,0,0-22.25068-22.25068H433.60965A22.25067,22.25067,0,0,0,411.359,107.00475v8.13468H371.62352a22.25067,22.25067,0,0,0-22.25067,22.25068V199.003H560.85494V137.39011A22.25067,22.25067,0,0,0,538.60427,115.13943Z"
                                                transform="translate(-204.73854 -84.75407)"
                                                fill="#3f3d56"
                                            />
                                            <path
                                                d="M440.85016,714.719c6.5698-4.49614,13.32206-9.17218,21.12382-11.21592,7.11815-1.86465,15.29169-1.37138,20.92177,3.84062,4.54356,4.20617,6.46192,10.671,3.09554,16.20474a10.29789,10.29789,0,0,1-6.82246,4.96408,8.21862,8.21862,0,0,1-7.38872-2.88661c-1.7651-1.95045-2.82116-4.83323-1.88322-7.40419.98627-2.70345,3.62463-4.3568,6.21589-5.27948a46.00407,46.00407,0,0,1,9.27017-1.915l12.89241-1.76494,52.76891-7.22392,58.76538-8.04482a1.83592,1.83592,0,0,0,1.27334-2.24265,1.87009,1.87009,0,0,0-2.24265-1.27334L491.90922,706.48508c-4.2295.579-8.51865.99359-12.70418,1.84428a21.363,21.363,0,0,0-9.12184,3.7888c-5.60138,4.43473-5.20124,12.27858-.04661,16.918,5.21814,4.69652,12.78382,3.888,17.36291-1.29921a15.74343,15.74343,0,0,0,1.19385-19.291c-4.99863-7.53725-14.10711-10.43294-22.81019-9.36395-10.06518,1.23628-18.59831,6.89386-26.77333,12.48856-1.92477,1.31724-.103,4.47836,1.84033,3.14842Z"
                                                transform="translate(-204.73854 -84.75407)"
                                                fill="#3f3d56"
                                            />
                                            <path
                                                d="M851.14474,579.59059l1.21542,12.15415s-7.29249,26.73911,8.5079,26.73911,3.64624-26.73911,3.64624-26.73911l1.21542-9.72332Z"
                                                transform="translate(-204.73854 -84.75407)"
                                                fill="#a0616a"
                                            />
                                            <path
                                                d="M961.54032,539.0976,955.96349,549.965s-20.63925,18.49815-7.36938,27.075,17.577-20.47743,17.577-20.47743l6.29883-7.50632Z"
                                                transform="translate(-204.73854 -84.75407)"
                                                fill="#a0616a"
                                            />
                                            <path
                                                d="M870.59137,590.52932l17.0158,115.46436,8.5079,82.64818s7.29249,18.23121,31.60078,2.43083l-3.64625-47.40116-2.43083-42.5395s-2.43082-41.32409-4.86165-48.61658,1.21541-31.60077,1.21541-31.60077l9.72332-40.10867Z"
                                                transform="translate(-204.73854 -84.75407)"
                                                fill="#2f2e41"
                                            />
                                            <path
                                                d="M898.5459,786.211l-9.72331,10.93873s-14.585-4.86166-18.23122,4.86165,27.95453,12.15415,27.95453,12.15415,32.81619,2.43083,32.81619,0-.8793-21.02177-5.30131-22.05732S898.5459,786.211,898.5459,786.211Z"
                                                transform="translate(-204.73854 -84.75407)"
                                                fill="#2f2e41"
                                            />
                                            <path
                                                d="M916.77712,584.45225l17.0158,121.54143,8.5079,82.64818s7.29248,18.23121,31.60077,2.43083l-3.64624-47.40116-2.43083-42.5395s-2.43083-41.32409-4.86166-48.61658,1.21542-31.60077,1.21542-31.60077l-2.43083-44.97033Z"
                                                transform="translate(-204.73854 -84.75407)"
                                                fill="#2f2e41"
                                            />
                                            <path
                                                d="M944.73165,786.211l-9.72332,10.93873s-14.585-4.86166-18.23121,4.86165,27.95453,12.15415,27.95453,12.15415,32.81618,2.43083,32.81618,0-.8793-21.02177-5.3013-22.05732S944.73165,786.211,944.73165,786.211Z"
                                                transform="translate(-204.73854 -84.75407)"
                                                fill="#2f2e41"
                                            />
                                            <circle cx="675.57615" cy="282.13901" r="25.5237" fill="#a0616a"/>
                                            <polygon
                                                points="694.415 293.685 705.354 311.917 677.399 324.071 673.753 302.193 694.415 293.685"
                                                fill="#a0616a"
                                            />
                                            <path
                                                d="M913.13087,394.84762s-25.5237-2.43083-35.247,13.36955L853.89409,426.4494a13.82858,13.82858,0,0,0-5.34159,12.82422L869.376,596.60639l30.38536-1.21541,49.832-9.72332,14.585-3.64624-7.29249-64.417s-8.5079-25.5237,1.21541-42.5395l-4.86165-59.5553L919.208,402.1401Z"
                                                transform="translate(-204.73854 -84.75407)"
                                                fill="#575a89"
                                            />
                                            <path
                                                d="M856.0064,431.31005l-1.82312-3.03854s-2.43083,0-2.43083,4.86166-4.25395,109.995-4.25395,109.995l-1.21541,40.10868,23.09287,2.43082,8.5079-75.35568-3.64624-49.832Z"
                                                transform="translate(-204.73854 -84.75407)"
                                                fill="#575a89"
                                            />
                                            <path
                                                d="M936.22375,413.07883l17.0158,2.43083a112.94944,112.94944,0,0,1,17.0158,23.09287c7.29248,13.36956,29.16994,38.89326,24.30828,58.33989S975.117,551.63606,975.117,551.63606l-23.09288-9.72331s18.23122-42.5395,17.0158-46.18574-15.80038-25.52371-15.80038-25.52371Z"
                                                transform="translate(-204.73854 -84.75407)"
                                                fill="#575a89"
                                            />
                                            <path
                                                d="M862.69118,352.91582s-20.662-1.21541-7.29249-14.585c0,0,10.93873-4.86166,15.80039-2.43083,0,0,7.29249-4.86166,7.29249-2.43083,0,0,25.5237-7.29248,29.16994,18.23122s-2.43083,37.67784-2.43083,37.67784l-17.0158-1.21541s3.64624-20.66205-9.72331-18.23122c0,0-10.93873,3.64625-12.15415,0S862.69118,352.91582,862.69118,352.91582Z"
                                                transform="translate(-204.73854 -84.75407)"
                                                fill="#2f2e41"
                                            />
                                            <polygon
                                                points="667.676 499.09 678.615 426.166 671.322 381.195 673.753 430.109 666.461 499.09 667.676 499.09"
                                                opacity="0.2"
                                            />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="step === 3">
                            <div class="justify-evenly flex flex-row flex-wrap">
                                <div
                                    class="flex flex-row w-full text-white"
                                    style="background: url('/tokengen-step4-background.png') center center; background-size: cover"
                                >
                                    <div class="flex flex-col w-1/2 p-3">
                                        <span class="text-3xl font-medium">{{ token.name }} ({{ token.symbol }})</span>
                                        <span class="text-xl font-medium">Supply: {{ initialBalanceFormatted }}</span>
                                        <span class="text-xl font-medium">Contract: {{ tokenType }}</span>
                                    </div>
                                    <div class="flex flex-col w-1/2 p-3 text-right">
                                        <span class="text-3xl font-medium">Review your contract</span>
                                        <span class="text-lg font-medium"
                                        >Contract deployment provided by tokengen.net</span
                                        >
                                    </div>
                                </div>
                                <div class="flex flex-row justify-center w-full py-10 text-3xl font-bold text-center">
                                    Contract features
                                </div>

                                <div
                                    class="sm:grid-cols-2 md:grid-cols-3 gap-x-16 lg:gap-x-6 gap-y-8 grid w-11/12 grid-cols-1 px-8"
                                >
                                    <div>
                                        <div
                                            class="flex items-center justify-center w-12 h-12 mb-4 text-red-600 bg-red-100 rounded-full"
                                        >
                                            <svg
                                                class="w-8 h-8"
                                                fill="none"
                                                stroke="currentColor"
                                                viewBox="0 0 24 24"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    stroke-width="2"
                                                    d="M9 3v2m6-2v2M9 19v2m6-2v2M5 9H3m2 6H3m18-6h-2m2 6h-2M7 19h10a2 2 0 002-2V7a2 2 0 00-2-2H7a2 2 0 00-2 2v10a2 2 0 002 2zM9 9h6v6H9V9z"
                                                ></path>
                                            </svg>
                                        </div>
                                        <h3
                                            class="lg:text-lg flex items-center mb-2 text-base font-semibold leading-tight text-gray-900"
                                        >
                                            <span class="mr-2">Custom token name and symbol</span>
                                            <BooleanIcon :bool="true"/>
                                        </h3>
                                        <p class="lg:text-base text-sm text-gray-500">
                                            Create your own token contract, using your desired name and symbol. This is
                                            available for all tokens.
                                        </p>
                                    </div>

                                    <div>
                                        <div
                                            class="flex items-center justify-center w-12 h-12 mb-4 text-green-600 bg-green-100 rounded-full"
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                class="w-6 h-6"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    stroke-width="2"
                                                    d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                                                />
                                            </svg>
                                        </div>
                                        <h3
                                            class="lg:text-lg flex items-center mb-2 text-base font-semibold leading-tight text-gray-900"
                                        >
                                            <span class="mr-2">Verified BscScan Contracts</span>
                                            <BooleanIcon :bool="true"/>
                                        </h3>
                                        <p class="lg:text-base text-sm text-gray-500">
                                            All contracts deployed from tokengen is automatically verified on BscScan.
                                            This is available for all tokens.
                                        </p>
                                    </div>

                                    <div>
                                        <div
                                            class="flex items-center justify-center w-12 h-12 mb-4 text-pink-600 bg-pink-100 rounded-full"
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                class="w-6 h-6"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    stroke-width="2"
                                                    d="M17.657 18.657A8 8 0 016.343 7.343S7 9 9 10c0-2 .5-5 2.986-7C14 5 16.09 5.777 17.656 7.343A7.975 7.975 0 0120 13a7.975 7.975 0 01-2.343 5.657z"
                                                />
                                                <path
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    stroke-width="2"
                                                    d="M9.879 16.121A3 3 0 1012.015 11L11 14H9c0 .768.293 1.536.879 2.121z"
                                                />
                                            </svg>
                                        </div>
                                        <h3
                                            class="lg:text-lg flex items-center mb-2 text-base font-semibold leading-tight text-gray-900"
                                        >
                                            <span class="mr-2">Burnable tokens</span>
                                            <BooleanIcon :bool="token.burnable"/>
                                        </h3>
                                        <p
                                            @click="setToken('BasicToken', true, 'cap', true)"
                                            class="mb-2 text-gray-500 underline cursor-pointer"
                                            v-if="!token.burnable"
                                        >
                                            Change to Basic to get this feature
                                        </p>
                                        <p class="lg:text-base text-sm text-gray-500">
                                            Burn tokens when you feel like. As a part of keeping up your liquidity or
                                            simply stabilizing your market. This is available with Basic and Pro tokens.
                                        </p>
                                    </div>

                                    <div>
                                        <div
                                            class="flex items-center justify-center w-12 h-12 mb-4 text-purple-600 bg-purple-100 rounded-full"
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                class="w-6 h-6"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    stroke-width="2"
                                                    d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3"
                                                />
                                            </svg>
                                        </div>
                                        <h3
                                            class="lg:text-lg flex items-center mb-2 text-base font-semibold leading-tight text-gray-900"
                                        >
                                            <span class="mr-2">Mintable tokens</span>
                                            <BooleanIcon :bool="token.mintable"/>
                                        </h3>
                                        <p
                                            @click="setToken('BasicToken', true, 'cap', true)"
                                            class="mb-2 text-gray-500 underline cursor-pointer"
                                            v-if="!token.mintable"
                                        >
                                            Change to Basic to get this feature
                                        </p>
                                        <p class="lg:text-base text-sm text-gray-500">
                                            Mint tokens if you need to add more tokens to your contract. This is
                                            available with Basic and Pro tokens.
                                        </p>
                                    </div>

                                    <div>
                                        <div
                                            class="flex items-center justify-center w-12 h-12 mb-4 text-indigo-600 bg-indigo-100 rounded-full"
                                        >
                                            <div class="relative">
                                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                    <path fill-rule="evenodd" d="M12.395 2.553a1 1 0 00-1.45-.385c-.345.23-.614.558-.822.88-.214.33-.403.713-.57 1.116-.334.804-.614 1.768-.84 2.734a31.365 31.365 0 00-.613 3.58 2.64 2.64 0 01-.945-1.067c-.328-.68-.398-1.534-.398-2.654A1 1 0 005.05 6.05 6.981 6.981 0 003 11a7 7 0 1011.95-4.95c-.592-.591-.98-.985-1.348-1.467-.363-.476-.724-1.063-1.207-2.03zM12.12 15.12A3 3 0 017 13s.879.5 2.5.5c0-1 .5-4 1.25-4.5.5 1 .786 1.293 1.371 1.879A2.99 2.99 0 0113 13a2.99 2.99 0 01-.879 2.121z" clip-rule="evenodd" />
                                                </svg>
                                            </div>
                                        </div>
                                        <h3
                                            class="lg:text-lg flex flex-col mb-2 text-base font-semibold leading-tight text-gray-900"
                                        >
                                            <div class="flex flex-row items-center">
                                                <span class="mr-2">Auto burn</span>
                                                <BooleanIcon :bool="token.features.transactionBurn.value"/>
                                            </div>
                                        </h3>
                                        <p
                                            @click="setToken('DeflationaryToken', true)"
                                            class="mb-2 text-gray-500 underline cursor-pointer"
                                            v-if="!token.features.transactionBurn.value"
                                        >
                                            Change to Deflationary to get this feature
                                        </p>
                                        <p class="lg:text-base text-sm text-gray-500">
                                            Automatically burn a percentage of every transaction.
                                        </p>
                                    </div>

                                    <div>
                                        <div
                                            class="flex items-center justify-center w-12 h-12 mb-4 text-blue-600 bg-blue-100 rounded-full"
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none"
                                                 viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                      d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z"/>
                                            </svg>
                                        </div>
                                        <h3
                                            class="lg:text-lg flex flex-col mb-2 text-base font-semibold leading-tight text-gray-900"
                                        >
                                            <div class="flex flex-row items-center">
                                                <span class="mr-2">Auto split</span>
                                                <BooleanIcon :bool="token.features.transactionSplit.value"/>
                                            </div>
                                        </h3>
                                        <p
                                            @click="setToken('DeflationaryToken', true)"
                                            class="mb-2 text-gray-500 underline cursor-pointer"
                                            v-if="!token.features.transactionSplit.value"
                                        >
                                            Change to Deflationary to get this feature
                                        </p>
                                        <p class="lg:text-base text-sm text-gray-500">
                                            Automatically split a percentage of every transaction between holders.
                                        </p>
                                    </div>
                                </div>
                                <div class="flex flex-row w-11/12 mt-8">
                                    <div class="flex flex-col w-1/3 p-1">
                                        <div class="p-3 mb-2 text-white bg-indigo-700 rounded">
                                            Choose deployment network
                                        </div>
                                        <div class="flex w-full mb-2" v-for="(network, k) in network.list">
                                            <button
                                                class="px-6 py-3 text-white bg-indigo-500 rounded"
                                                :key="k"
                                                @click="changeNetwork(k)"
                                            >
                                                <span class="mr-2" :class="{ 'opacity-0': currentNetwork !== k }"
                                                >&check;</span
                                                >
                                                {{ network.name }}
                                            </button>
                                        </div>
                                    </div>
                                    <div class="flex flex-col w-1/3 p-1">
                                        <div
                                            class="p-3 mb-2 text-white text-gray-600 bg-yellow-100 border-yellow-300 rounded"
                                        >
                                            <div class="max-w-1/2">
                                                <div v-if="currentNetwork === 'testnet'">
                                                    <p class="font-bold">Test net</p>
                                                    <p>You've chosen to deploy on the TEST network.</p>
                                                    <p v-if="wrongNetwork" class="mt-1 italic">
                                                        Your MetaMask is currently not connected, or on the wrong
                                                        network.
                                                    </p>
                                                </div>
                                                <div v-if="currentNetwork === 'mainnet'">
                                                    <p class="font-bold">Main net</p>
                                                    <div v-if="wrongNetwork" class="mt-1 italic">
                                                        Your MetaMask is currently not connected, or on the wrong
                                                        network.
                                                    </div>
                                                </div>
                                                <div
                                                    @click="connectWallet"
                                                    v-if="!$store.state.walletConnected"
                                                    class="mt-1 underline cursor-pointer"
                                                >
                                                    Click here to connect your wallet
                                                </div>
                                            </div>
                                        </div>
                                        <div class="p-3 mb-2 text-white text-gray-600 rounded">
                                            <div>
                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        class="form form-checkbox"
                                                        v-model="agreement"
                                                    />
                                                    By checking this box you agree to our <a href="/terms-of-service"
                                                                                             target="_blank"
                                                                                             class="underline" t>Terms
                                                    of Use</a>.
                                                </label>
                                            </div>
                                            <div class="mt-2" v-if="currentNetwork === 'mainnet'">
                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        class="form form-checkbox"
                                                        v-model="mainAgreement"
                                                    />
                                                    I accept that I'm deploying onto the main net
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex flex-col w-1/3 p-1">
                                        <div class="p-3 mb-2 text-white bg-indigo-700 rounded">Pricing</div>
                                        <div class="flex w-full mb-2">
                                            <div
                                                class="text-grey-dark flex items-center w-1/2 p-3 px-3 text-sm leading-normal text-white whitespace-no-wrap bg-indigo-500 border border-r-0 border-indigo-500 rounded rounded-r-none"
                                            >
                                                Affiliate / Discount code
                                            </div>
                                            <div class="border-r-none w-1/2 p-3 font-light border rounded-l rounded-r">
                                                    <div class="mt-1 relative rounded-md shadow-sm">
                                                        <input type="text" placeholder="Discount code" class="block w-full pr-10 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md" v-model="discountCode" @input="checkDiscountCode" />
                                                        <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                            <p class="text-sm text-green-600" v-if="discountCodeValid">&check;</p>
                                                            <p class="text-sm text-red-600" v-if="discountCodeValid === false">&cross;</p>
                                                        </div>
                                                    </div>
                                            </div>
                                        </div>
                                        <div class="flex w-full mb-2">
                                            <div
                                                class="text-grey-dark flex items-center w-1/2 p-3 px-3 text-sm leading-normal text-white whitespace-no-wrap bg-indigo-500 border border-r-0 border-indigo-500 rounded rounded-r-none"
                                            >
                                                Commission fee
                                            </div>
                                            <div class="border-r-none w-1/2 p-3 font-light border rounded-l rounded-r">
                                                {{ token.price - (token.price * discountValue) }} BNB
                                            </div>
                                        </div>
                                        <div class="flex w-full">
                                            <div
                                                class="text-grey-dark flex items-center w-1/2 p-3 px-3 text-sm leading-normal text-white whitespace-no-wrap bg-indigo-500 border border-r-0 border-indigo-500 rounded rounded-r-none"
                                            >
                                                Gas fee (estimated)
                                            </div>
                                            <div class="border-r-none w-1/2 p-3 font-light border rounded-l rounded-r">
                                                {{ token.gas }} gwei
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="w-full" v-if="step !== maxSteps">
                    <div class="flex justify-between w-full">
                        <div class="w-1/2 px-12 ml-1 text-left">
                            <button
                                v-if="step > 1"
                                @click="decrementStep"
                                class="focus:outline-none hover:bg-gray-100 w-32 px-5 py-2 font-medium text-center text-gray-600 bg-white border rounded-lg shadow-sm"
                            >
                                Previous
                            </button>
                        </div>

                        <div class="w-1/2 px-12 mr-1 text-right">
                            <button
                                v-if="step < maxSteps - 1 && step > 1"
                                @click="incrementStep"
                                class="focus:outline-none hover:bg-blue-600 w-32 px-5 py-2 font-medium text-center text-white bg-indigo-500 border border-transparent rounded-lg shadow-sm"
                            >
                                Next
                            </button>

                            <button
                                @click="deploy()"
                                :disabled="!canDeploy || checkingDiscountCode"
                                v-if="step === 3"
                                class="focus:outline-none w-32 px-5 py-2 font-medium text-center text-white border border-transparent rounded-lg shadow-sm"
                                :class="{
                                    'bg-gray-300 cursor-not-allowed': !canDeploy,
                                    'bg-indigo-500 hover:bg-blue-600': canDeploy,
                                }"
                            >
                                Deploy
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.form-checkbox,
.form-radio {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    display: inline-block;
    vertical-align: middle;
    background-origin: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    flex-shrink: 0;
    color: currentColor;
    background-color: #fff;
    border-color: #e2e8f0;
    border-width: 1px;
    height: 1.4em;
    width: 1.4em;
}

.form-checkbox {
    border-radius: 0.25rem;
}

.form-radio {
    border-radius: 50%;
}

.form-checkbox:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e");
    border-color: transparent;
    background-color: currentColor;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
}

.form-radio:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
    border-color: transparent;
    background-color: currentColor;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
}
</style>
<script>
import dapp from '../dapp';
import tokenDetails from '../tokenDetails';
import commissionContract from '../abi/service/CommissionReceiver.json';
import Tooltip from './Tooltip';

import Complete from './generator/Complete';
import TokenLine from './generator/TokenLine';
import Card from './generator/Card';
import TextInput from './generator/TextInput';
import NumberInput from './generator/NumberInput';
import BooleanIcon from './BooleanIcon';
import FeatureLines from "./generator/FeatureLines";

import {debounce} from 'lodash'

export default {
    name: 'Generator',
    mixins: [dapp, tokenDetails],
    components: {
        FeatureLines,
        Tooltip,
        Complete,
        TokenLine,
        Card,
        TextInput,
        NumberInput,
        BooleanIcon,
    },
    computed: {
        initialBalanceFormatted: {
            get() {
                return String(this.token.initialBalance || 0).replace(/(.)(?=(\d{3})+$)/g, '$1,');
            },
        },
        capFormatted: {
            get() {
                return String(this.token.cap || 0).replace(/(.)(?=(\d{3})+$)/g, '$1,');
            },
        },
        initialBalance: {
            get() {
                const n = this.token.initialBalance;
                if (n < 1e3) return n || 0;
                if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + 'K';
                if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + 'M';
                if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + 'B';
                if (n >= 1e12 && n < 1e15) return +(n / 1e12).toFixed(1) + 'T';
                if (n >= 1e15) return +(n / 1e15).toFixed(1) + 'Q';
            },
        },
        cap: {
            get() {
                const n = this.token.cap;
                if (n < 1e3) return n || 0;
                if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + 'K';
                if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + 'M';
                if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + 'B';
                if (n >= 1e12 && n < 1e15) return +(n / 1e12).toFixed(1) + 'T';
                if (n >= 1e15) return +(n / 1e15).toFixed(1) + 'Q';
            },
        },
        canDeploy: {
            get() {
                const a = this.correctNetwork && this.agreement;
                return a && (this.currentNetwork === 'mainnet' ? this.mainAgreement : true);
            },
        },
        correctNetwork: {
            get() {
                return !this.wrongNetwork;
            },
        },
    },
    data() {
        return {
            errors: {token: {}},
            wrongNetwork: false,
            walletConnected: false,
            highlighted: [],
            step: 1,
            maxSteps: 4,
            selectedToken: null,
            loading: true,
            currentNetwork: null,
            tokenType: '',
            trx: {
                hash: '',
                link: '',
            },
            transactionStarted: false,
            makingTransaction: false,
            failed: false,
            formDisabled: false,
            feeAmount: '0',
            agreement: false,
            mainAgreement: false,
            loadingPrices: true,
            discountCode: "",
            discountCodeValid: null,
            affiliateCodeValid: null,
            discountValue: 0,
            checkingDiscountCode: false,
            token: {
                address: '',
                link: '',
                name: '',
                symbol: '',
                decimals: 18,
                cap: 21000000,
                initialBalance: 21000000,
                supplyType: 'Fixed',
                accessType: 'None',
                fixed: false,
                mintable: false,
                burnable: false,
                operable: false,
                tokenRecover: false,
                removeCopyright: false,
                canChange: true,
            },
        };
    },
    beforeMount() {

    },
    mounted() {
        this.tokenType = 'BasicToken';
        this.currentNetwork = this.network.default;
        this.updateTokenDetails(true)
        this.updateTokenPrices()
        this.refreshNetwork();

        window.ethereum.on('chainChanged', (chain) => {
            const NewNetworkId = parseInt(chain, 16);
            let NewNetwork;
            for (const t of Object.keys(this.network.list)) {
                if (this.network.list[t].id === NewNetworkId) {
                    NewNetwork = t;
                }
            }
            if (!NewNetwork) return;

            this.changeNetwork(NewNetwork);
        });
    },
    methods: {
        async updateTokenPrices() {
            for (const token of this.tokens) {
                token.price = await this.getTokenPrice(token.type);
            }
            this.loadingPrices = false;
        },
        async connectWallet() {
            await this.$store.dispatch('connectWallet');
        },
        async getTokenPrice(tokenName) {
            const provider = new window.Web3.providers.HttpProvider('https://bsc-dataseed.binance.org/');
            const web3 = new window.Web3(provider);
            const contract = new web3.eth.Contract(commissionContract.abi, '0x1E4FF1d9D062066bc04565e0A171b26CaaAFe756');
            let price;
            await contract.methods
                .getPrice(tokenName)
                .call()
                .then((p) => {
                    price = p;
                })
                .catch((err) => console.log(err));
            return price / 1e18;
        },
        changeNetwork(network) {
            if (!this.network.list[network]) {
                this.$vToastify.error('Network is not supported', 'Error!');
            }
            this.currentNetwork = network;
            this.network.current = this.network.list[network];
            this.refreshNetwork();
        },
        async refreshNetwork() {
            await this.initDapp();
            this.wrongNetwork = this.metamask.netId !== this.network.current.id;
            this.$forceUpdate();
        },
        incrementStep() {
            if (this.step === this.maxSteps) return;

            this.errors = {token: {}};
            if (this.step === 2) {
                if (this.token.name.length === 0) {
                    this.errors.token.name = 'Missing token name';
                }
                if (this.token.symbol.length === 0) {
                    this.errors.token.symbol = 'Missing token symbol';
                }
                if (!this.token.initialBalance) {
                    this.errors.token.initialBalance = 'Missing initial supply value';
                }
                if (this.token.initialBalance <= 0) {
                    this.errors.token.initialBalance = 'Initial supply cannot be zero or negative';
                }
                if (this.token.mintable && !this.token.cap) {
                    this.errors.token.cap = 'Missing total supply value';
                }
                if (this.token.decimals < 0) {
                    this.errors.token.decimals = 'Decimal precision cannot be negative';
                }
                if (this.token.decimals > 18) {
                    this.errors.token.decimals = 'Decimal precision cannot be higher than 18';
                }
                if (this.token.mintable && this.token.initialBalance > this.token.cap) {
                    this.errors.token.initialBalance = 'Initial supply cannot be higher than total supply';
                }
                if (Object.keys(this.errors.token).length) return;
                if (!this.token.mintable) {
                    this.token.cap = this.token.initialBalance;
                }

                this.$store.dispatch('token/update', this.token)
            }

            if (this.step === 3) {
                this.refreshNetwork();
            }
            this.step++;
        },
        decrementStep() {
            if (this.step === 0) return;
            this.step--;
        },
        setToken(token, stepTo = true, highlight = null, fromCheckout = false) {
            this.tokenType = token;
            this.selectedToken = this.tokenDetails.find((t) => t.type === token);
            if (this.tokenType === "FreeToken") {
                this.$store.state.token.initialBalance = 21000000
                this.token.initialBalance = 21000000
            }
            this.loadToken();
            if (highlight) {
                this.highlighted.push(highlight);
            }
            if (stepTo) {
                this.step = 2;
            }
            if (fromCheckout) this.gaSend('token_selection', 'from_checkout' + this.token.type);
            else this.gaSend('token_selection', 'manually_selected' + this.token.type);
        },
        deploy() {
            this.step = this.maxSteps;
            this.updateSupply();
            this.updateCap();
            this.generateToken();
        },
        async initDapp() {
            this.network.current = this.network.list[this.currentNetwork];
            try {
                await this.initWeb3(this.currentNetwork, true);
                this.initService(this.currentNetwork);
                await this.loadToken();
                await this.loadToken();
            } catch (e) {
                this.$vToastify.error('Failed to initialize Web3', 'Error!');
            }
        },
        async loadToken() {
            this.initToken(this.tokenType || "BasicToken");
            this.updateTokenDetails();
            this.updateSupply();
            this.updateCap();

            try {
                this.feeAmount = await this.promisify(this.contracts.service.methods.getPrice(this.tokenType).call);
            } catch (e) {
                if (this.currentNetwork === 'mainnet') {
                    this.$vToastify.warning(
                        'We are having an issue with Current Network Provider. Please switch Network or try again later.',
                    );
                    this.feeAmount = this.web3.utils.toWei('0', 'ether');
                } else {
                    this.feeAmount = this.web3.utils.toWei(`${this.token.price}`, 'ether');
                }
            }

            if (this.currentNetwork === 'mainnet') {
                this.gaSend('ViewContent', this.tokenType, '');
            }

            this.loading = false;
        },
        async generateToken() {
            if (!this.metamask.installed) {
                this.$vToastify.warning('To create a Token please install MetaMask!');
                return;
            } else {
                if (this.metamask.netId !== this.network.current.id) {
                    this.wrongNetwork = true;
                    this.$vToastify.warning(
                        `Your MetaMask in on the wrong network. Please switch on ${this.network.current.name} and try again!`,
                    );
                    return;
                }
            }

            try {
                this.trx.hash = '';
                this.trx.link = '';
                this.formDisabled = true;
                this.makingTransaction = true;
                console.log(this.getDeployArguments())
                await this.web3Provider.request({method: 'eth_requestAccounts'});

                const tokenContract = new this.web3.eth.Contract(this.contracts.token.abi);

                const deployOptions = {
                    data: this.contracts.token.bytecode,
                    arguments: this.getDeployArguments(),
                };

                const sendOptions = {
                    from: await this.promisify(this.web3.eth.getCoinbase),
                    value: this.web3.utils.toWei(`${this.token.price - (this.token.price * this.discountValue)}`, 'ether'),
                    gasPrice: '10000000000', // default gas price 10 gwei
                };

                sendOptions.gas = await this.estimateDeployGas(tokenContract, deployOptions, sendOptions);

                this.gaSend('deployment', 'deployment_started', '');

                tokenContract
                    .deploy(deployOptions)
                    .send(sendOptions)
                    .on('error', (error) => {
                        this.makingTransaction = false;
                        this.formDisabled = false;

                        this.failed = true;

                        this.$vToastify.error(error.message, 'Error!');

                        if (error.message.includes('User denied')) {
                            this.gaSend('deployment', 'deployment_cancelled', '');
                        } else {
                            this.gaSend('deployment', 'deployment_error', '');
                        }
                    })
                    .on('transactionHash', (transactionHash) => {
                        this.transactionStarted = true;
                        this.trx.hash = transactionHash;
                        this.trx.link = `${this.network.current.explorerLink}/tx/${this.trx.hash}`;
                        this.gaSend('deployment', 'transaction_complete', '');
                    })
                    .on('receipt', (receipt) => {
                        this.token.address = receipt.contractAddress;
                        this.token.link = this.network.current.explorerLink + '/token/' + this.token.address;
                        this.$forceUpdate();
                        this.$vToastify.success('Your Token has been deployed!', 'Well done!');
                        this.gaSend('deployment', 'deployment_complete', '');
                    });
            } catch (e) {
                this.makingTransaction = false;
                this.formDisabled = false;
                this.$vToastify.error(e.message, 'Error!');
                if (e.message.includes('User denied')) {
                    this.gaSend('deployment', 'deployment_cancelled', '');
                } else {
                    this.gaSend('deployment', 'deployment_error', '');
                }
            }
        },
        updateTokenDetails(init = false) {
            const detail = this.tokenDetails.find((elem) => elem.type === this.tokenType);

            this.token = detail;

            this.token.name = this.$store.state.token.name
            this.token.symbol = this.$store.state.token.symbol
            this.token.decimals = this.$store.state.token.decimals
            this.token.cap = this.$store.state.token.cap
            this.token.initialBalance = this.$store.state.token.initialBalance
            this.token.transactionSplit = this.$store.state.token.transactionSplit
            this.token.transactionBurn = this.$store.state.token.transactionBurn

            if (!init)
                this.token.gas = this.web3.utils.toBN(detail.gas);
            this.features = []
            for (let feature of Object.keys(this.token.features)) {
                this.features.push(this.token.features[feature])
            }
            for (let t of this.features) {
                if (t.default && !this.$store.state.token[t.bind])
                    this.token[t.bind] = t.default
            }
        },
        updateCap() {
            this.token.cap = ['100k', 'Fixed'].includes(this.token.supplyType)
                ? this.token.initialBalance
                : this.token.cap;
        },
        updateSupply() {
            this.token.initialBalance = this.token.supplyType === '100k' ? 100000 : this.token.initialBalance;
        },
        getDeployArguments() {

            const name = this.token.name;
            const symbol = this.token.symbol;
            const decimals = this.web3.utils.toBN(this.token.decimals);
            const cap = this.web3.utils
                .toBN(this.token.cap || 0)
                .mul(this.web3.utils.toBN(Math.pow(10, this.token.decimals)));
            const initialBalance = this.web3.utils
                .toBN(this.token.initialBalance || 0)
                .mul(this.web3.utils.toBN(Math.pow(10, this.token.decimals))); // eslint-disable-line max-len

            const transactionSplit = this.web3.utils.toBN((parseFloat(this.token.transactionSplit) || 0)*100)
            const transactionBurn = this.web3.utils.toBN((parseFloat(this.token.transactionBurn) || 0)*100)
            const maxTransfer = this.web3.utils.toBN(parseInt(this.token.maxTransfer) || 0)
            console.log(transactionSplit, transactionBurn, maxTransfer)
            const params = [name, symbol, decimals];
            const t = this.token;
            if (t.arguments.includes('cap'))
                params.push(cap);
            if (t.arguments.includes('initialBalance'))
                params.push(initialBalance);
            if (t.arguments.includes('transactionSplit'))
                params.push(transactionSplit);
            if (t.arguments.includes('transactionBurn'))
                params.push(transactionBurn);
            if (t.arguments.includes('maxTransfer'))
                params.push(maxTransfer);
            if (t.arguments.includes('commissionReceiver'))
                params.push("0x1E4FF1d9D062066bc04565e0A171b26CaaAFe756");
            if (t.arguments.includes('discountCode'))
                params.push(this.discountCode);

            return params;
        },
        async estimateDeployGas(tokenContract, deployOptions, sendOptions) {
            try {
                const gas = await this.promisify(tokenContract.deploy(deployOptions).estimateGas, sendOptions);

                return this.web3.utils.toBN(gas).muln(1.3); // add 30% tolerance
            } catch (e) {
                this.$vToastify.info('Failed to estimate gas price, using precalculated values.');
                return this.token.gas;
            }
        },
        async addToMetaMask() {
            try {
                await this.web3Provider.request({
                    method: 'wallet_watchAsset',
                    params: {
                        type: 'ERC20',
                        options: {
                            address: this.token.address,
                            symbol: this.token.symbol.substr(0, 5),
                            decimals: this.token.decimals,
                        },
                    },
                });
            } catch (e) {
                this.$vToastify.warning('Failed to add token to MetaMask');
            }
        },
         checkDiscountCode: debounce(async function(e){
            const provider = new window.Web3.providers.HttpProvider('https://bsc-dataseed.binance.org/');
            const web3 = new window.Web3(provider);
            const contract = new web3.eth.Contract(commissionContract.abi, '0x1E4FF1d9D062066bc04565e0A171b26CaaAFe756');
             let isDiscountCode = false
            await contract.methods.isAffiliateCode(this.discountCode)
                .call()
                .then(async (isAffiliate) => {
                    if(!isAffiliate){

                        await contract.methods.discountCodeExists(this.discountCode)
                        .call()
                        .then(isDiscount => {
                                isDiscountCode = isDiscount
                        })
                    }
                    if(!isAffiliate && !isDiscountCode){
                        this.discountCodeValid = false
                        this.discountValue = 0
                        return;
                    }
                    if(isDiscountCode){
                        await contract.methods.getDiscountCodeValues(this.discountCode)
                            .call()
                            .then(values => {
                                if(values[1] === 0) // No more tickets
                                    return this.discountCodeValid = false
                                this.discountValue = values[0]/10000;
                            })

                        return this.discountCodeValid = true
                    }
                    if(isAffiliate){
                        await contract.methods.getAffiliateCodeValues(this.discountCode)
                            .call()
                            .then(values => {
                                this.discountValue = values[0]/10000;
                            })

                        return this.discountCodeValid = true
                    }
                })
                .catch((err) => console.log(err));
            console.log(`checking discount code: "${this.discountCode}"`)
        }, 500)
    },
};
</script>
