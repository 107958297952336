const vars = require('./.env.json');

module.exports = {
    plugins: [
        [
            '@vuepress/google-analytics',
            {
                ga: vars.gaId,
            },
        ],
        [
            'vuepress-plugin-facebook-pixel',
            {
                pixelId: vars.fbPixelId,
            },
        ],
    ],
    defaultNetwork: vars.defaultNetwork,
    serviceReceiver: vars.serviceReceiver,
};
