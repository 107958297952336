<template>
    <div class="mb-4">
        <div class="relative flex flex-wrap items-stretch w-full">
            <div class="flex -mr-px">
                <div
                    class="flex items-center px-3 text-sm leading-normal text-white whitespace-no-wrap bg-indigo-500 border border-r-0 border-indigo-500 rounded rounded-r-none"
                >
                    {{ text }} <span class="pl-1 text-red-500" v-if="required">*</span>
                </div>
            </div>
            <input
                type="number"
                pattern="[0-9]*"
                inputmode="numeric"
                :min="min"
                :max="max"
                :step="step"
                v-model="localValue"
                :readonly="readonly"
                :class="{
                    'bg-gray-100 cursor-not-allowed': readonly,
                    'rounded-r-none': extra !== false,
                    'border-red-500': error || highlighted,
                }"
                class="border-grey-light focus:border-indigo-500 focus:shadow relative flex-1 flex-auto flex-grow flex-shrink w-px h-10 px-3 leading-normal transition-all duration-150 border rounded rounded-l-none outline-none"
                :placeholder="text"
            />
            <div class="flex -mr-px" v-if="extra !== false">
                <div
                    class="flex items-center px-3 text-sm leading-normal text-white whitespace-no-wrap bg-indigo-500 border border-r-0 border-indigo-500 rounded rounded-l-none"
                >
                    {{ extra }}
                </div>
            </div>
        </div>
        <div class="flex flex-col -mr-px">
            <div v-if="error" class="flex items-center px-1 text-xs leading-normal text-red-500 whitespace-no-wrap">
                {{ error }}
            </div>
            <div
                class="flex items-center px-1 text-xs leading-normal text-gray-500 whitespace-no-wrap"
                v-html="help"
            ></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TextInput',
    props: {
        text: String,
        readonly: Boolean,
        value: Number | String,
        help: String,
        required: Boolean,
        error: String,
        highlighted: Boolean,
        extra: {
            type: Number | String,
            default: false,
        },
        min: {
            type: Number,
            default: 1,
        },
        max: {
            type: Number,
            default: 1e18,
        },
        step: {
            type: Number,
            default: 1,
        },
    },
    computed: {
        localValue: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
    },
    mounted() {
        setTimeout(() => (this.highlighted = false), 2500);
    },
};
</script>

<style scoped></style>
