<template>
    <li class="flex flex-row items-center mb-3 space-x-2">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            v-if="active"
            class="w-5 h-5 text-green-500"
            viewBox="0 0 20 20"
            fill="currentColor"
        >
            <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clip-rule="evenodd"
            />
        </svg>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            v-else
            class="w-5 h-5 text-red-500"
            viewBox="0 0 20 20"
            fill="currentColor"
        >
            <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                clip-rule="evenodd"
            />
        </svg>
        <span>{{ feature.cardText }}</span>
        <info-icon v-popover.right="{ name: name }"></info-icon>
        <popover :name="name" event="hover">
            <div class="flex flex-col text-left text-black">
                <div class="p-2 border-b font-medium">
                    {{ feature.cardText }}
                </div>
                <div class="p-2">
                    {{feature.cardHelp}}
                </div>
            </div>
        </popover>

    </li>
</template>

<script>
import InfoIcon from "../InfoIcon";
export default {
    name: 'TokenLine',
    components: {InfoIcon},
    props: {
        active: Boolean,
        feature: Object
    },
    computed: {
        name: {
            get(){
                return this.feature.cardText
            }
        }
    }
};
</script>

<style scoped>
.vue-popover.dropdown-position-right{
    width: 300px !important;
}
</style>
