<template>
    <div>
        <token-line v-for="feature in features" v-if="feature.shown" :active="feature.value" :feature="feature"></token-line>
    </div>
</template>

<script>
import TokenLine from "./TokenLine";
export default {
    name: "FeatureLines",
    components: {TokenLine},
    props: {
        token: {
            type: Object,
            required: true
        }
    },
    data(){
        return {
            features: []
        }
    },
    mounted() {
        for(let feature of Object.keys(this.token.features)){
            this.features.push(this.token.features[feature])
        }
    }
}
</script>

<style scoped>
</style>
