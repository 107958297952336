<template>
    <div class="md:w-1/2 lg:w-1/3 w-full px-3 mb-6">
        <div
            class="px-8 pt-12 pb-8 text-center bg-white rounded-lg shadow"
            :class="{
                'text-white rounded-lg shadow-2xl lg:scale-105 bg-gradient-to-br from-blue-500 via-indigo-600 to-purple-600':
                    token.highlighted && !token.coming_soon,
                'text-white rounded-lg shadow-2xl lg:scale-105 bg-gradient-to-br from-gray-400 to-gray-600':
                    token.coming_soon,
            }"
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-24 h-24 mx-auto mb-8"
                viewBox="0 0 2000 2000"
                :class="{ 'text-gray-300': token.coming_soon }"
            >
                <g fill="currentColor">
                    <path
                        d="M611.59 840.42l388.4-388.39 388.6 388.59 226-226L999.99 0 385.6 614.42l225.99 226M.006 999.969l226.007-226.007 225.992 225.993L226 1225.96zM611.59 1159.58l388.4 388.39 388.59-388.58 226.12 225.88-.11.12L999.99 2000l-614.41-614.4-.32-.32 226.33-225.7M1548.013 1000.093l226.007-226.006 225.992 225.992-226.006 226.007z"
                    />
                    <path
                        d="M1229.22 999.88h.1L999.99 770.55 830.51 940.03h-.01l-19.47 19.48-40.16 40.17-.32.31.32.33 229.12 229.13 229.33-229.33.11-.13-.21-.11"
                    />
                </g>
            </svg>
            <h3 class="font-heading mb-4 text-4xl font-bold">{{ token.type.replace('Token','') }}</h3>
            <span class="font-heading flex items-center justify-center text-4xl font-bold" v-if="!loading"
            >{{ token.price }}<span class="ml-1 text-xs">BNB</span></span
            >
            <svg
                class="w-10 h-10 mx-auto"
                viewBox="0 0 38 38"
                xmlns="http://www.w3.org/2000/svg"
                v-if="loading"
                stroke="currentColor"
            >
                <g fill="none" fill-rule="evenodd">
                    <g transform="translate(1 1)" stroke-width="2">
                        <circle stroke-opacity=".5" cx="18" cy="18" r="18"/>
                        <path d="M36 18c0-9.94-8.06-18-18-18">
                            <animateTransform
                                attributeName="transform"
                                type="rotate"
                                from="0 18 18"
                                to="360 18 18"
                                dur="1s"
                                repeatCount="indefinite"
                            />
                        </path>
                    </g>
                </g>
            </svg>
            <p class="mt-3 mb-8 text-sm">{{ token.description }}</p>
            <div class="flex flex-col items-center mb-8">
                <ul>
                    <feature-lines :token="token"/>
                </ul>
            </div>
            <div class="flex justify-center">
                <button
                    v-if="!token.coming_soon"
                    @click="$parent.setToken(token.type)"
                    class="sm:mb-0 block px-6 py-5 mb-4 text-base font-semibold leading-none text-center rounded-lg"
                    :class="{
                        'bg-blue-600 text-white hover:bg-blue-700': !token.highlighted,
                        'bg-white text-gray-700 hover:bg-gray-200': token.highlighted,
                    }"
                >
                    Get Started
                </button>

                <p
                    class="sm:mb-0 block px-6 py-5 mb-4 text-base font-semibold leading-none text-center rounded-lg"
                    v-if="token.coming_soon"
                >
                    Coming soon!
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import TokenLine from './TokenLine';
import MainButton from '../MainButton';
import FeatureLines from "./FeatureLines";

export default {
    name: 'Card',
    components: {FeatureLines, MainButton, TokenLine},
    props: ['token', 'loading'],
};
</script>

<style scoped></style>
