<template>
    <div>
        <generator></generator>
    </div>
</template>

<script>
import Generator from '../components/Generator';
export default {
    name: 'GeneratePage',
    components: {
        Generator,
    },
};
</script>

<style scoped></style>
